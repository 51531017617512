import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Link as LinkMUI, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';

import AppBar from 'components/AppBar';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import {
  FormulasSelectionMap,
  ProjectContext,
  ProjectContextData,
} from 'components/Context/ProjectContext';
import MenuProject from 'components/Layout/LayoutProject/MenuProject';
import {
  getFirstStep,
  getStepFromPath,
  isStepDisabled,
  ProjectStep,
  projectStepsData,
} from 'models/oav/ProjectStep.models';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { EstimationDialog } from 'pages/project/EstimationDialog';
import { TransitionSlideUP } from 'utils/animations/transitions';
import { projectToFormulasSelectionMap } from 'utils/project/project';
import { CommercialPropositionDialog } from 'pages/project/CommercialPropositionDialog';

const LayoutProject: React.FC = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (!id) return null;

  const theme = useTheme();
  const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));
  const [estimationDialogOpen, setEstimationDialogOpen] = useState(false);
  const [commercialPropositionDialogOpen, setCommercialPropositionDialogOpen] =
    useState(false);

  const [dataCurrent, setDataCurrent] = useState<ProjectContextData>({
    project: undefined,
  });

  // Get project.
  const projectQuery = useQuery(projectQueries.getById(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));

  const persistedSelectedFormulas = useMemo<FormulasSelectionMap>(
    () => projectToFormulasSelectionMap(projectQuery.data),
    [projectQuery.data],
  );

  const [selectedFormulas, setSelectedFormulas] =
    useState<FormulasSelectionMap>(persistedSelectedFormulas);

  useEffect(() => {
    setSelectedFormulas(persistedSelectedFormulas);
  }, [persistedSelectedFormulas, pathname]);

  const resetSelectedFormulas = useCallback(() => setSelectedFormulas({}), []);

  const title = useMemo(() => {
    const step = Object.entries(projectStepsData).find(([, v]) => {
      return pathname.endsWith(`/${v.path}`);
    });

    if (
      step &&
      step[1].order >= projectStepsData[ProjectStep.CLIENTS_INFO]!.order
    ) {
      return 'Souscription';
    }
    return 'Project';
  }, [pathname]);

  // Check ProjectStep on path change.
  useEffect(() => {
    if (!projectQuery.data) return;

    const currentStep = getStepFromPath(pathname);

    if (!currentStep) return;

    if (!projectQuery.data.currentStep) {
      if (currentStep !== getFirstStep())
        navigate(projectStepsData[getFirstStep()]!.path);
      return;
    }

    if (isStepDisabled(currentStep, projectQuery.data.currentStep)) {
      navigate(projectStepsData[projectQuery.data.currentStep]!.path);
    }
  }, [pathname, projectQuery.data]);

  useEffect(() => {
    setDataCurrent(prev => ({ ...prev, project: projectQuery.data }));
  }, [pathname, projectQuery.data]);

  return (
    <ProjectContext.Provider
      value={{
        setSelectedFormulas,
        selectedFormulas,
        resetSelectedFormulas,
        estimationDialogOpen,
        closeEstimationDialog: () => setEstimationDialogOpen(false),
        openEstimationDialog: () => setEstimationDialogOpen(true),
        commercialPropositionDialogOpen,
        closeCommercialPropositionDialog: () =>
          setCommercialPropositionDialogOpen(false),
        openCommercialPropositionDialog: () =>
          setCommercialPropositionDialogOpen(true),
        dataCurrent,
        dataPersisted: dataCurrent,
        setDataCurrent,
        setDataPersisted: setDataCurrent,
      }}
    >
      <Dialog
        open={estimationDialogOpen}
        onClose={() => setEstimationDialogOpen(false)}
        maxWidth="md"
        fullScreen={!screenSizeUpSM}
        TransitionComponent={TransitionSlideUP}
      >
        <EstimationDialog />
      </Dialog>
      <Dialog
        open={commercialPropositionDialogOpen}
        onClose={() => setCommercialPropositionDialogOpen(false)}
        maxWidth="md"
        fullScreen={!screenSizeUpSM}
        TransitionComponent={TransitionSlideUP}
      >
        <CommercialPropositionDialog />
      </Dialog>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          sx={{
            width: '100%',
          }}
        >
          <Breadcrumbs separator={<ChevronRightIcon size="small" />}>
            <Link to="/projets" style={{ color: 'inherit' }}>
              <LinkMUI underline="hover" color="inherit">
                {title}
              </LinkMUI>
            </Link>
          </Breadcrumbs>
        </Stack>
      </AppBar>

      <Box
        sx={{
          width: '100%',
          maxWidth: 1250,
          mx: 'auto',
        }}
      >
        <Grid container spacing={2}>
          <Grid item md xs={12}>
            <Outlet />
          </Grid>

          <Grid
            item
            md="auto"
            xs={12}
            sx={{
              mt: 5,
            }}
          >
            <Box
              sx={{
                position: 'sticky',
                top: 24,
                width: '250px',
              }}
            >
              {projectQuery.isError || offersMapQuery.isError ? (
                <></>
              ) : projectQuery.isLoading ||
                !projectQuery.data ||
                offersMapQuery.isLoading ||
                !offersMapQuery.data ? (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="200px"
                />
              ) : (
                <MenuProject
                  project={projectQuery.data}
                  offersMap={offersMapQuery.data}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ProjectContext.Provider>
  );
};

export default LayoutProject;
