import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useProjectContext } from 'components/Context/ProjectContext';
import TextField from 'components/TextField/TextField';
import React, { useMemo, useRef } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import Link from '@mui/material/Link';
import { CardOfferSmall } from 'components/Card/CardOfferSmall';
import { useCreateEstimationMutation } from 'api/oav/Estimation.api';
import { Estimation, EstimationRequest } from 'models/oav/Estimation.models';
import { useAppContext } from 'components/Context/AppContext';
import {
  getDefaultErrorSnackBar,
  getDefaultSuccessSnackBar,
} from 'utils/snackbars/Snackbars';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { yupEmail } from 'utils/validation/yupPerson';
import Skeleton from '@mui/material/Skeleton';
import { ErrorMessage } from 'components/Message/ErrorMessage';
import CardFormula from 'components/Card/CardFormula';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';
import { offerQueries } from 'api/referentiels/OfferQuery.api';
import { messageFieldRequired } from 'utils/validation/message';
import { CloseIcon } from 'components/Icon/CloseIcon';
import { Icon } from 'components/Icon';
import { useTheme } from '@mui/material/styles';
import { ProjectStep, projectStepsData } from 'models/oav/ProjectStep.models';
import { useProjectStepProposal } from 'api/oav/ProjectStep.api';

export const validationSchema = Yup.object().shape({
  email: yupEmail.required(messageFieldRequired),
});

const estimationOfferDocumentsMap: { [key: string]: string[] } = {
  '312': [
    'DOCUMENT_INFORMATION',
    'REGLEMENT_MUTUALISTE',
    'CONDITIONS_GENERALES_ASSISTANCE',
  ],
  '512': [
    'DOCUMENT_INFORMATION',
    'REGLEMENT_MUTUALISTE',
    'CONDITIONS_GENERALES_ASSISTANCE',
  ],
  '539': [
    'DOCUMENT_INFORMATION',
    'CONDITIONS_GENERALES',
    'CONDITIONS_GENERALES_ASSISTANCE',
  ],
};

export const EstimationDialog = () => {
  const { id: projectId } = useParams();
  const { pathname } = useLocation();
  const validatedWithMail = useRef(false);

  if (!projectId) return <></>;

  const theme = useTheme();

  const projectQueryOptions = projectQueries.getById(projectId);
  const projectQuery = useQuery(projectQueryOptions);
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(projectId));

  const { closeEstimationDialog, selectedFormulas: selectedFormulasMap } =
    useProjectContext();

  const { addSnackbar } = useAppContext();
  const queryClient = useQueryClient();

  const project = projectQuery.data;
  // TODO: Handle multi subscriptions
  const subscription = project?.subscriptions?.[0];

  const offerCode = subscription?.offerCode || '';

  const offer = offersMapQuery.data?.[offerCode];

  const formulaCodes = useMemo(
    () => selectedFormulasMap[subscription?.id || ''] ?? [],
    [selectedFormulasMap, subscription],
  );

  const { data: documents, error: errorDocuments } = useQuery({
    ...offerQueries.getByCodeDocuments(offerCode),
    enabled: !!offerCode,
  });

  const tarificationsQuery = useQuery({
    ...subscriptionQueries.getByIdTarification(
      projectId,
      subscription?.id || '',
    ),
    enabled: !!subscription?.id,
  });

  const handleSuccess = async (
    data: Estimation,
    variables: {
      projectId: string;
      subscriptionId: string;
      payload: EstimationRequest;
    },
  ) => {
    if (variables.payload.sendEmail) {
      addSnackbar(
        getDefaultSuccessSnackBar('Le devis a été envoyé avec succès.'),
      );
    } else {
      window.open(data.downloadUrl, '_blank');
    }

    try {
      await queryClient.invalidateQueries({
        queryKey: projectQueryOptions.queryKey,
      });
    } catch (e) {
      // Do not throw error if the invalidate fails
      console.error(e);
    }

    closeEstimationDialog();
  };

  const handleError = () => {
    addSnackbar(
      getDefaultErrorSnackBar(
        'Une erreur est survenue durant la génération du devis.',
      ),
    );
  };

  const mutationProposalStep = useProjectStepProposal(projectId, {});

  const { isPending: isMutationPending, mutate } = useCreateEstimationMutation(
    handleSuccess,
    handleError,
  );

  const title = 'Édition de devis';

  if (errorDocuments || offersMapQuery.error || projectQuery.error) {
    return <ErrorDialog onClose={closeEstimationDialog} title={title} />;
  }

  if (!documents || !project || !subscription || !offer) {
    return <LoadingDialog title={title} />;
  }

  const documentsToDisplay = documents?.filter(_ =>
    estimationOfferDocumentsMap[subscription.offerCode]?.includes(_.type.code),
  );

  // If user on proposal step & estimation is sent then validate proposal step to save formula selection
  const shouldValidateProposalStep = pathname.endsWith(
    `/${projectStepsData[ProjectStep.PROPOSAL]?.path}`,
  );

  const mutateProposalStep = async () =>
    mutationProposalStep.mutateAsync({
      subscriptions: Object.entries(selectedFormulasMap).map(
        ([id, formulaCodes]) => ({
          id,
          shoppingCartItems: formulaCodes.map(formulaCode => ({
            formulaCode,
          })),
        }),
      ),
    });

  const handleSubmitWithEmail = async (values: { email: string }) => {
    validatedWithMail.current = true;
    try {
      if (shouldValidateProposalStep) {
        await mutateProposalStep();
      }
      mutate({
        projectId: project.id,
        subscriptionId: subscription.id,
        payload: {
          formulaCodes,
          sendEmail: true,
          email: values.email,
        },
      });
    } catch (e) {
      handleError();
    }
  };

  const handleSubmitWithoutEmail = async () => {
    validatedWithMail.current = false;
    try {
      if (shouldValidateProposalStep) {
        await mutateProposalStep();
      }
      mutate({
        projectId: project.id,
        subscriptionId: subscription.id,
        payload: {
          formulaCodes,
          sendEmail: false,
        },
      });
    } catch (e) {
      handleError();
    }
  };

  const loadingWithMail =
    (isMutationPending || mutationProposalStep.isPending) &&
    validatedWithMail.current;
  const loadingWithoutMail =
    (isMutationPending || mutationProposalStep.isPending) && !loadingWithMail;

  return (
    <Formik
      initialValues={{
        email: project.subscriber?.person?.email ?? '',
      }}
      onSubmit={handleSubmitWithEmail}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <Form>
          <DialogTitle>{title}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeEstimationDialog}
            disabled={isMutationPending}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              ':hover': {
                backgroundColor: 'rgba(250, 250, 250, 0.04)',
              },
            }}
          >
            <Icon color={theme.palette.text.primary}>
              <CloseIcon />
            </Icon>
          </IconButton>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CardOfferSmall offer={offer} />
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  Vous avez sélectionné les formules suivantes :
                </Typography>
                <Grid container spacing={2}>
                  {offer.formulas
                    .filter(_ => formulaCodes.includes(_.code))
                    .map(_ => {
                      const pricing = tarificationsQuery.data
                        ? tarificationsQuery.data.formulas?.find(
                            f => f.code === _.code,
                          )?.tarification?.pricing
                        : undefined;
                      return (
                        <Grid key={_.code} item sm xs={12} sx={{ mt: 2 }}>
                          <CardFormula
                            key={_.code}
                            formula={_}
                            pricing={pricing}
                            recommended={
                              _.code === subscription?.recommendedFormulaCode
                            }
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>

              {documentsToDisplay && documentsToDisplay.length > 0 && (
                <Grid item xs={12}>
                  <Typography>
                    Vous allez éditer <b>votre devis</b> qui doit être
                    accompagné des éléments suivants :
                  </Typography>
                  <ul>
                    {documentsToDisplay.map(document => (
                      <li key={document.id}>
                        <Link
                          target="_blank"
                          href={document.downloadUrl}
                          sx={{ textDecoration: 'underline' }}
                        >
                          {document.type.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography>
                  <b>Envoyez par mail</b> l&apos;ensemble des pièces ou&nbsp;
                  <b>téléchargez votre devis</b> ainsi que les documents
                  d&apos;informations légales ci-dessus à remettre à votre
                  client.
                </Typography>
                <TextField
                  variant="filled"
                  sx={{
                    mt: 2,
                  }}
                  fullWidth
                  id="email"
                  name="email"
                  label="Email de votre client"
                  value={values.email}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{ minLength: 1, maxLength: 100 }}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions
            sx={{
              p: 2,
            }}
          >
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item sm="auto" xs={12}>
                <Button
                  fullWidth
                  variant="text"
                  color="default"
                  onClick={closeEstimationDialog}
                  disabled={isMutationPending}
                  sx={{
                    px: 4,
                  }}
                >
                  Annuler
                </Button>
              </Grid>
              <Grid item sm="auto" xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="gradient"
                  color="primary"
                  disabled={loadingWithMail || loadingWithoutMail}
                  sx={{
                    px: 4,
                  }}
                >
                  {loadingWithMail ? (
                    <CircularProgress color="inherit" size={28} />
                  ) : (
                    'Envoyer par mail'
                  )}
                </Button>
              </Grid>
              <Grid item sm="auto" xs={12}>
                <Button
                  fullWidth
                  variant="gradient"
                  color="primary"
                  onClick={handleSubmitWithoutEmail}
                  disabled={loadingWithMail || loadingWithoutMail}
                  sx={{
                    px: 4,
                  }}
                >
                  {loadingWithoutMail ? (
                    <CircularProgress color="inherit" size={28} />
                  ) : (
                    'Télécharger'
                  )}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

const LoadingDialog = ({ title }: { title: string }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="50px"
        width={'500px'}
      />
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="100px"
        width={'500px'}
      />
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="50px"
        width={'500px'}
      />
    </DialogContent>
  </>
);

const ErrorDialog = ({
  title,
  onClose,
}: {
  title: string;
  onClose: () => void;
}) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <ErrorMessage message="Une erreur est survenue" />
    </DialogContent>
    <DialogActions>
      <Button color="default" onClick={onClose}>
        Fermer
      </Button>
    </DialogActions>
  </>
);
