import {
  Contract,
  ContractSearchRequest,
  Status,
  statusLabelColors,
} from 'models/oav/Contract.model';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { TableLinearLoading } from 'components/Table/TableLinearLoading';
import { LoadingTableRows } from 'components/Table/LoadingTableRows';
import { TableRowLink } from 'components/Table/TableRowLink';
import dayjs from 'dayjs';
import { TypographyStatus } from 'components/TypographyStatus/TypographyStatus';
import { Pagination } from 'models/Page.models';
import { useQuery } from '@tanstack/react-query';
import { contractGetListOptions } from 'api/oav/ContractQuery.api';
import { ErrorMessage } from 'components/Message/ErrorMessage';
import { TablePagination } from 'components/Table/TablePagination';

export type ContractColumnKey =
  | keyof Contract
  | 'subscriber'
  | 'subscriberCode';

export type ContractTab = {
  key: ContractColumnKey;
  sort?: boolean;
};

interface ContractTableProps {
  tabs: ContractTab[];
  searchRequest: ContractSearchRequest;
  onContractFetched: (total: number) => void;
  pageSize: number;
  emptyMessage: string;
}

export const ContractTable: React.FC<ContractTableProps> = ({
  searchRequest,
  tabs,
  onContractFetched,
  pageSize,
  emptyMessage,
}) => {
  // sorts
  const [orderBy, setOrderBy] = useState<string>('effectiveDate');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [sortCriteria, setSortCriteria] = useState<string>('-effectiveDate');

  // init pagination
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    size: pageSize,
  });

  const {
    data: contracts,
    error,
    isFetching,
  } = useQuery(contractGetListOptions(pagination, searchRequest, sortCriteria));

  useEffect(() => {
    onContractFetched(contracts?.totalElements || 0);
  }, [contracts]);

  const findStatusColor = (statusKey: Status) => {
    return statusLabelColors[statusKey].color;
  };

  const propertiesMap = new Map<
    ContractColumnKey,
    { label: string; render: (contract: Contract) => any }
  >([
    [
      'effectiveDate',
      {
        label: "Date d'effet",
        render: contract => {
          return (
            <>
              {contract.effectiveDate
                ? dayjs(contract.effectiveDate)
                    .format('DD MMM YYYY')
                    .toLowerCase()
                : ''}
            </>
          );
        },
      },
    ],
    [
      'cancelDate',
      {
        label: 'Date de résiliation',
        render: contract => {
          return (
            <>
              {contract.cancelDate
                ? dayjs(contract.cancelDate).format('DD MMM YYYY').toLowerCase()
                : ''}
            </>
          );
        },
      },
    ],
    [
      'code',
      {
        label: 'Contrat',
        render: contract => {
          return <>{contract.code}</>;
        },
      },
    ],
    [
      'offerLabel',
      {
        label: 'Offre',
        render: contract => {
          return <>{contract.offerLabel}</>;
        },
      },
    ],
    [
      'riskLabel',
      {
        label: 'Risque',
        render: contract => {
          return (
            <TypographyStatus
              borderLeftOnly
              statusColor={findStatusColor(contract.status)}
            >
              {contract.riskLabel}
            </TypographyStatus>
          );
        },
      },
    ],
    [
      'segmentLabel',
      {
        label: 'Segment',
        render: contract => {
          return <>{contract.segmentLabel}</>;
        },
      },
    ],
    [
      'status',
      {
        label: 'Etat',
        render: contract => {
          return (
            <TypographyStatus statusColor={findStatusColor(contract.status)}>
              {contract.status}
            </TypographyStatus>
          );
        },
      },
    ],
    [
      'subscriberCode',
      {
        label: 'Identifiant',
        render: contract => {
          return <>{contract.subscriber.code}</>;
        },
      },
    ],
    [
      'subscriber',
      {
        label: 'Souscripteur',
        render: contract => {
          return (
            <>{`${contract.subscriber.title} ${contract.subscriber.lastName} ${contract.subscriber.firstName}`}</>
          );
        },
      },
    ],
  ]);

  const onSort = (property: string) => {
    if (property !== orderBy) {
      if (property === 'subscriber') {
        setSortCriteria(`+subscriberLastName,+subscriberFirstName`);
      } else {
        setSortCriteria(`+${property}`);
      }
      setSortDirection('asc');
    } else {
      const direction = sortDirection === 'asc' ? '-' : '+';
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
      if (property === 'subscriber') {
        setSortCriteria(
          `${direction}subscriberLastName,${direction}subscriberFirstName`,
        );
      } else {
        setSortCriteria(`${direction}${property}`);
      }
    }

    setOrderBy(property);
  };

  const handlePageChange = (_: unknown, newPage: number) => {
    setPagination(p => ({ ...p, page: newPage }));
  };

  const handleRowsPerPageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPagination(p => ({ ...p, size: parseInt(e.target.value) }));
  };

  useEffect(() => {
    setPagination(p => ({ ...p, page: 0 }));
  }, [searchRequest]);

  return (
    <Box sx={{ width: '100%', pb: 2 }}>
      <TableContainer sx={{ width: '100%', pt: 2 }}>
        {error ? (
          <ErrorMessage message="Une erreur est survenu lors de la récupération des contrats." />
        ) : (
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow sx={{ position: 'relative' }}>
                {isFetching && <TableLinearLoading />}
                {tabs.map(t => {
                  return (
                    <TableCell key={`header-${t.key}`}>
                      {t.sort && (
                        <TableSortLabel
                          active={orderBy === t.key}
                          onClick={() => onSort(t.key)}
                          direction={orderBy === t.key ? sortDirection : 'asc'}
                          sx={{ '.MuiTableSortLabel-icon': { opacity: 0.3 } }}
                        >
                          {propertiesMap.get(t.key)?.label}
                        </TableSortLabel>
                      )}
                      {!t.sort && (
                        <Typography>
                          {propertiesMap.get(t.key)?.label}
                        </Typography>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <LoadingTableRows
                  rowSize={pagination.size!}
                  cellSize={tabs.length}
                />
              ) : contracts && contracts?.totalElements != 0 ? (
                contracts.content.map((contract, idx) => {
                  const to = `/portefeuille/${contract.code}`;
                  return (
                    <TableRowLink to={to} key={`entry-${idx}`}>
                      {tabs.map((t, tabIndex) => {
                        return (
                          <TableCell
                            sx={{
                              textOverflow: 'ellipsis',
                              borderTopLeftRadius:
                                tabIndex === 0 ? '1.5rem' : '0',
                              borderBottomLeftRadius:
                                tabIndex === 0 ? '1.5rem' : '0',
                              borderTopRightRadius:
                                tabIndex === tabs.length - 1 ? '1.5rem' : '0',
                              borderBottomRightRadius:
                                tabIndex === tabs.length - 1 ? '1.5rem' : '0',
                              minWidth: t.key === 'status' ? '150px' : '0',
                              fontWeight:
                                t.key === 'effectiveDate' || t.key === 'code'
                                  ? 'bold'
                                  : 'normal',
                            }}
                            key={`entry-${idx}-${t.key}`}
                          >
                            {propertiesMap.get(t.key)?.render(contract)}
                          </TableCell>
                        );
                      })}
                    </TableRowLink>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={tabs.length}
                    sx={{ opacity: 0.8, textAlign: 'center' }}
                  >
                    {emptyMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={tabs.length}
                  rowsPerPageOptions={[10, 25, 50]}
                  count={contracts?.totalElements ?? 0}
                  rowsPerPage={pagination.size!}
                  page={pagination.page!}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  sx={{
                    '.MuiTablePagination-spacer': { display: 'none' },
                    '.MuiTablePagination-toolbar': {
                      margin: 'auto',
                      width: 'max-content',
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
};
