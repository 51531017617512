export type Contract = {
  intermediaryId: string;
  segmentLabel?: string;
  riskLabel?: string;
  offerLabel?: string;
  status: Status;
  effectiveDate?: Date;
  cancelDate?: Date;
  code: string;
  subscriber: Subscriber;
};

export interface ContractSearchRequest {
  statuses?: string[];
  segments?: string[];
  risks?: string[];
  offers?: string[];
  subscriberCodes?: string[];
  search?: string;
}

export type Subscriber = {
  code: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  inseeCode: string;
  birthDate: Date | null;
};

export enum Status {
  'Résilié' = 'Résilié',
  'En cours' = 'En cours',
}

export const statusLabelColors: { [key in Status]: { label: string; color: string } } = {
  [Status["En cours"]]: { label: 'En cours', color: '#52E09C' },
  [Status["Résilié"]]: { label: 'Résilié', color: '#F33535' },
};

export type ContractStatus = {
  key: Status;
  label: string;
  color: string;
};

export const enumContractStatus: ContractStatus[] = [
  {
    key: Status["En cours"],
    label: 'En cours',
    color: '#52E09C',
  },
  {
    key: Status["Résilié"],
    label: 'Résilié',
    color: '#F33535',
  },
];
