import { queryOptions } from '@tanstack/react-query';

import { useAccessToken } from 'utils/api/api';
import { fetchSupportingDocuments } from 'api/oav/SupportingDocument.api';

const defaultStaleTime = 10 * 60 * 1000;

export const supportingDocumentsQueries = {
  getSupportingDocumentsOptions: (projectId: string) =>
    getSupportingDocumentsOptions(projectId),
};

const getSupportingDocumentsOptions = (projectId: string) => {
  const token = useAccessToken();

  return queryOptions({
    queryKey: ['projects', projectId, 'supporting-documents'],
    queryFn: () => fetchSupportingDocuments(token, projectId),
    staleTime: defaultStaleTime,
    gcTime: defaultStaleTime,
  });
};
