import { NavigateFunction } from 'react-router/dist/lib/hooks';
import {
  getNextStep,
  getPreviousStep,
  ProjectStep,
  projectStepsData,
} from 'models/oav/ProjectStep.models';
import { Project } from 'models/oav/Project.models';
import { FormulasSelectionMap } from 'components/Context/ProjectContext';

export const navigateToNextStep = (
  currentStep: ProjectStep,
  projectId: string,
  navigate: NavigateFunction,
) => {
  const nextStep = getNextStep(currentStep);
  if (nextStep)
    navigate(`/projets/${projectId}/${projectStepsData[nextStep]!.path}`);
};

export const navigateToPreviousStep = (
  currentStep: ProjectStep,
  projectId: string,
  navigate: NavigateFunction,
) => {
  const nextStep = getPreviousStep(currentStep);
  if (nextStep)
    navigate(`/projets/${projectId}/${projectStepsData[nextStep]!.path}`);
};

export const projectToFormulasSelectionMap = (project?: Project) =>
  project?.subscriptions?.reduce<FormulasSelectionMap>(
    (previousValue, currentValue) => {
      previousValue[currentValue.id] =
        currentValue.shoppingCartItems?.map(item => item.formulaCode) || [];
      return previousValue;
    },
    {},
  ) || {};
