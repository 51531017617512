import { RestError } from 'errors/RestError';
import { NeededSupportingDocument } from 'models/oav/SupportingDocument.models';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const fetchSupportingDocuments = async (
  accessToken: string,
  projectId: string,
): Promise<NeededSupportingDocument[]> => {
  const url = `${baseUrl}/${projectId}/supporting-document`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};
