import React, { useMemo, useState } from 'react';

import dayjs from 'dayjs';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueries, useQuery } from '@tanstack/react-query';
import { Form, Formik } from 'formik';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';

import { appName } from 'App';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import {
  navigateToNextStep,
  navigateToPreviousStep,
} from 'utils/project/project';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';
import { useProjectContext } from 'components/Context/ProjectContext';
import { ChipOfferRisk } from 'components/Chip/ChipOfferRisk';
import { Offer } from 'models/referentiels/Offer.model';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';
import CardFormula from 'components/Card/CardFormula';
import { FieldReadOnly } from 'components/FieldReadOnly/FieldReadOnly';
import { DialogBeneficiaries } from 'components/Dialog/DialogBeneficiaries';
import { useProjectStepFormulaChoice } from 'api/oav/ProjectStep.api';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { useAppContext } from 'components/Context/AppContext';
import { ButtonBeneficiaries } from 'components/Button/ButtonBeneficiaries';

const CURRENT_STEP = ProjectStep.FORMULA_CHOICE;

type FormValues = { [key: string]: string | null };

const formulaValidationSchema = Yup.string().required(
  'Veuillez sélectionner une formule.',
);

const ProjectFormulaChoicePage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) return <></>;

  const theme = useTheme();

  const projectQuery = useQuery(projectQueries.getById(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));

  const tarificationsQueries = useQueries({
    queries:
      projectQuery.data?.subscriptions?.map(_ =>
        subscriptionQueries.getByIdTarification(id, _.id),
      ) || [],
  });

  const { selectedFormulas: allSelectedFormulas } = useProjectContext();
  const { addSnackbar } = useAppContext();

  const [dialogBeneficiariesContext, setDialogBeneficiariesContext] = useState<{
    beneficiaryIds: string[];
    offer?: Offer;
  }>({
    beneficiaryIds: [],
  });
  const [openDialogBeneficiaries, setOpenDialogBeneficiaries] =
    useState<boolean>(false);

  const initialValues: FormValues = useMemo(() => {
    const values: FormValues = {};
    projectQuery.data?.subscriptions?.forEach(_ => {
      values[_.id] = _.formulaCode || null;
    });
    return values;
  }, [projectQuery.data]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape(
      Object.keys(initialValues).reduce<Record<string, Yup.StringSchema>>(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue]: formulaValidationSchema,
        }),
        {},
      ),
    );
  }, [initialValues]);

  const { mutate, isPending } = useProjectStepFormulaChoice(id, {
    onSuccess: () => {
      navigateToNextStep(CURRENT_STEP, id, navigate);
    },
    onError: () => {
      addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  });

  if (projectQuery.error || offersMapQuery.error) {
    const error = projectQuery.error || offersMapQuery.error;
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: 200,
        }}
      >
        <CardError
          status={error instanceof RestError ? error.status : undefined}
        />
      </Grid>
    );
  }

  if (!projectQuery.data || !offersMapQuery.data) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  const subscriptions = projectQuery.data?.subscriptions ?? [];

  const handleSubmit = (values: FormValues) => {
    mutate({
      subscriptions: Object.entries(values).map(([key, value]) => ({
        id: key,
        formulaCode: value!,
      })),
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, touched, errors }) => (
        <Form>
          <DialogBeneficiaries
            project={projectQuery.data}
            offer={dialogBeneficiariesContext.offer}
            beneficiaryIds={dialogBeneficiariesContext.beneficiaryIds}
            onClose={() => setOpenDialogBeneficiaries(false)}
            onCancel={() => setOpenDialogBeneficiaries(false)}
            onValidate={() => setOpenDialogBeneficiaries(false)}
            open={openDialogBeneficiaries}
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h3">Choix de la couverture</Typography>
            </Grid>

            <Grid item xs={12}>
              <Stack
                gap={2}
                alignItems="space-between"
                direction="column"
                flexGrow={1}
              >
                {subscriptions.map((subscription, idx) => {
                  const offer = offersMapQuery.data[subscription.offerCode];
                  const selectedFormula = values[subscription.id];
                  const formulas =
                    offer.formulas.filter(_ =>
                      allSelectedFormulas[subscription.id]?.includes(_.code),
                    ) || [];
                  const tarificationsQuery = tarificationsQueries[idx];

                  return (
                    <Card
                      key={subscription.id}
                      sx={{
                        p: 2,
                        border: 2,
                        borderColor: theme.palette.divider,
                        backgroundColor: theme.palette.background.default,
                      }}
                    >
                      <Grid
                        container
                        key={subscription.id}
                        spacing={1}
                        alignItems={'center'}
                      >
                        <Grid item xs={4}>
                          <Stack
                            gap={1}
                            alignItems="space-between"
                            sx={{ paddingRight: 4 }}
                          >
                            <Typography variant="body1">
                              <Stack
                                direction="row"
                                gap={1}
                                flexWrap="wrap-reverse"
                                useFlexGap
                              >
                                <b>{offer?.label}</b>

                                {offer?.risks?.map(_ => (
                                  <ChipOfferRisk key={_.id} risk={_} />
                                ))}
                              </Stack>
                            </Typography>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <Stack
                                alignItems="stretch"
                                gap={1}
                                flexWrap="wrap"
                                useFlexGap
                                direction="column"
                              >
                                <FieldReadOnly
                                  sx={{ width: '100%' }}
                                  label="Début du contrat"
                                  value={dayjs(subscription.dateStart).format(
                                    'DD/MM/YYYY',
                                  )}
                                />
                                <ButtonBeneficiaries
                                  project={projectQuery.data}
                                  beneficiariesLength={
                                    subscription.beneficiaries?.length ?? 0
                                  }
                                  sx={{
                                    width: '100%',
                                    backgroundColor: '#00000020',
                                  }}
                                  onClick={() => {
                                    setDialogBeneficiariesContext({
                                      beneficiaryIds:
                                        subscription.beneficiaries?.map(
                                          _ => _.id || '',
                                        ) || [],
                                      offer,
                                    });
                                    setOpenDialogBeneficiaries(true);
                                  }}
                                />
                              </Stack>
                            </Grid>
                          </Stack>
                        </Grid>
                        <Grid item xs={8}>
                          <Grid container spacing={1}>
                            {formulas.map(_ => {
                              const pricing =
                                tarificationsQuery.data?.formulas?.find(
                                  f => f.code === _.code,
                                )?.tarification?.pricing;
                              const selected = selectedFormula === _.code;

                              return (
                                <Grid item key={_.code} xs={4}>
                                  <CardFormula
                                    formula={_}
                                    pricing={pricing}
                                    recommended={
                                      _.code ===
                                      subscription.recommendedFormulaCode
                                    }
                                    selected={selectedFormula === _.code}
                                    onClick={() => {
                                      setFieldValue(
                                        subscription.id,
                                        selected ? null : _.code,
                                      );
                                    }}
                                  />
                                </Grid>
                              );
                            })}
                            {touched[subscription.id] &&
                              errors[subscription.id] && (
                                <Grid item xs={12}>
                                  <FormHelperText error>
                                    {errors[subscription.id]}
                                  </FormHelperText>
                                </Grid>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  );
                })}
              </Stack>
            </Grid>

            <Grid item sm="auto" xs={12}>
              <Button
                fullWidth
                color="default"
                disabled={isPending}
                onClick={() =>
                  navigateToPreviousStep(CURRENT_STEP, id, navigate)
                }
                sx={{ px: 4 }}
              >
                Retour
              </Button>
            </Grid>

            <Grid item sm="auto" xs={12}>
              <Button
                fullWidth
                type="submit"
                disabled={isPending}
                sx={{ px: 4 }}
              >
                {isPending ? (
                  <CircularProgress color="inherit" size={28} />
                ) : (
                  'Valider'
                )}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectFormulaChoicePage;
