import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CardOfferLink from 'components/Card/CardOfferLink';
import CardBeneficiaryLink from 'components/Card/CardBeneficiaryLink';
import { useProjectContext } from 'components/Context/ProjectContext';
import { CheckCircleFilledIcon } from 'components/Icon/CheckCircleFilledIcon';
import { CheckCircleOutlinedBlankIcon } from 'components/Icon/CheckCircleOutlinedBlankIcon';
import { getSize } from 'components/Icon/settings';
import { Project } from 'models/oav/Project.models';
import { Subscription } from 'models/oav/Subscription.models';
import { OffersMap } from 'models/referentiels/Offer.model';
import {
  isStepDisabled,
  isStepDone,
  ProjectStep,
  projectStepsData,
} from 'models/oav/ProjectStep.models';

const MenuStep = (props: {
  label: string;
  to: string;
  chip?: string;
  done?: boolean;
  active?: boolean;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {props.done ? (
        <CheckCircleFilledIcon
          size="small"
          style={{
            color: theme.palette.success.main,
          }}
        />
      ) : (
        <CheckCircleOutlinedBlankIcon size="small" />
      )}
      <Link
        to={props.to}
        style={{ pointerEvents: props.disabled ? 'none' : 'auto', flexGrow: 1 }}
      >
        <Card
          sx={{
            px: 1,
            borderRadius: 2,
            color: props.disabled
              ? theme.palette.text.disabled
              : props.active
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            background: props.active
              ? theme.palette.primary.main + '30'
              : 'none',
            ':hover': {
              background: props.disabled
                ? 'none'
                : props.active
                  ? theme.palette.primary.main + '30'
                  : theme.palette.primary.main + '20',
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>{props.label}</Typography>
            </Stack>
            {props.chip && <Chip label={props.chip} size="small" />}
          </Stack>
        </Card>
      </Link>
    </Stack>
  );
};

const MenuStepContent = (props: { children: any }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Box
        sx={{
          width: `${getSize('small').width}px`,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{
            m: 0,
          }}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {props.children}
      </Box>
    </Stack>
  );
};

export type MenuProjectProps = {
  project: Project;
  offersMap: OffersMap;
};

const MenuProject: React.FC<MenuProjectProps> = (props: MenuProjectProps) => {
  const { pathname } = useLocation();

  const context = useProjectContext();
  const theme = useTheme();
  const { dataCurrent, selectedFormulas } = useProjectContext();

  const project = dataCurrent.project || props.project;

  const estimationDisabled =
    (project.currentStep ? projectStepsData[project.currentStep]!.order : 0) <
      projectStepsData[ProjectStep.PROPOSAL]!.order ||
    !!Object.entries(selectedFormulas).find(([, _]) => _.length === 0);

  const commercialPropositionDisabled =
    (project.currentStep ? projectStepsData[project.currentStep]!.order : 0) <
    projectStepsData[ProjectStep.FORMULA_CHOICE]!.order;

  return (
    <Stack>
      {Object.entries(projectStepsData)
        .sort(([, dataA], [, dataB]) => dataA.order - dataB.order)
        .map(([_, data]) => {
          const step = _ as ProjectStep;
          const disabled = isStepDisabled(step, project.currentStep);
          const done = isStepDone(step, project.currentStep);
          const chip =
            step === ProjectStep.SUBSCRIPTIONS
              ? project.subscriptions?.length.toString() || '0'
              : step === ProjectStep.BENEFICIARIES
                ? `${
                    (project.children?.length || 0) +
                    (project.subscriber ? 1 : 0) +
                    (project.partner ? 1 : 0)
                  }`
                : undefined;

          return (
            <>
              <MenuStep
                label={data.label}
                to={data.path}
                done={done}
                disabled={disabled}
                active={matchPath(data.pathMatch, pathname) !== null}
                chip={chip}
              />

              {step === ProjectStep.SUBSCRIPTIONS ? (
                <MenuStepContent>
                  {project.subscriptions?.map((_: Subscription) => {
                    const offer = props.offersMap[_.offerCode];
                    const formula = offer?.formulas.find(
                      f => f.code === _.formulaCode,
                    );
                    if (!offer) return;
                    return (
                      <CardOfferLink
                        key={_.offerCode}
                        offer={offer}
                        formula={formula}
                      />
                    );
                  })}
                </MenuStepContent>
              ) : step === ProjectStep.CLIENTS_INFO ? (
                <MenuStepContent>
                  {project.subscriber && (
                    <CardBeneficiaryLink
                      key="souscripteur"
                      to="donnees-clients#souscripteur"
                      beneficiary={project.subscriber}
                      disabled={disabled}
                    />
                  )}
                  {project.partner && (
                    <CardBeneficiaryLink
                      key="conjoint"
                      to="donnees-clients#conjoint"
                      beneficiary={project?.partner}
                      disabled={disabled}
                    />
                  )}
                  {project?.children !== undefined &&
                    project.children.map((_, index: number) => (
                      <CardBeneficiaryLink
                        key={`enfant${index + 1}`}
                        to={`donnees-clients#enfant${index + 1}`}
                        beneficiary={_}
                        disabled={disabled}
                      />
                    ))}
                </MenuStepContent>
              ) : step === ProjectStep.PROPOSAL ? (
                <MenuStepContent>
                  <Button
                    variant="gradient"
                    sx={{
                      marginY: 1,
                      background:
                        'linear-gradient(270deg, #52E09C 0%, #59CCD3 100%)',
                      color: theme.palette.background.default,
                    }}
                    onClick={context.openEstimationDialog}
                    fullWidth
                    size="small"
                    disabled={estimationDisabled}
                  >
                    Éditer un devis
                  </Button>
                </MenuStepContent>
              ) : step === ProjectStep.FORMULA_CHOICE ? (
                <MenuStepContent>
                  <Button
                    variant="gradient"
                    sx={{
                      marginY: 1,
                      background:
                        'linear-gradient(270deg, #52E09C 0%, #59CCD3 100%)',
                      color: theme.palette.background.default,
                    }}
                    onClick={context.openCommercialPropositionDialog}
                    fullWidth
                    size="small"
                    disabled={commercialPropositionDisabled}
                  >
                    Éditer un projet
                  </Button>
                </MenuStepContent>
              ) : (
                <></>
              )}
            </>
          );
        })}
    </Stack>
  );
};

export default MenuProject;
