import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useDebounce } from 'use-debounce';

import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { appName } from 'App';
import CardError from 'components/Card/CardError';
import CardFormula from 'components/Card/CardFormula';
import {
  FormulasSelectionMap,
  useProjectContext,
} from 'components/Context/ProjectContext';
import DatePicker from 'components/DatePicker/DatePicker';
import { useAppContext } from 'components/Context/AppContext';
import { ChevronBottomIcon } from 'components/Icon/ChevronBottomIcon';
import WarrantySectionAccordion from 'content/warranty/WarrantySectionAccordion';
import { RestError } from 'errors/RestError';
import useOnScreen from 'utils/hooks/useOnScreen';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { messageFieldRequired } from 'utils/validation/message';
import {
  dateStartDayMax,
  dateStartDayMin,
  getYupFieldDateStart,
} from 'utils/validation/yupSubscription';
import { useAccessToken } from 'utils/api/api';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { updateProjectStepProposal } from 'api/oav/ProjectStep.api';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';
import { updateSubscription } from 'api/oav/Subscription.api';
import { offerQueries } from 'api/referentiels/OfferQuery.api';
import { ApiResponse } from 'models/api.model';
import { Project, ProjectStepProposal } from 'models/oav/Project.models';
import {
  getNextStep,
  getPreviousStep,
  ProjectStep,
  projectStepsData,
} from 'models/oav/ProjectStep.models';
import {
  Subscription,
  SubscriptionRequest,
  SubscriptionRequestErrors,
} from 'models/oav/Subscription.models';
import { warrantyQueries } from 'api/referentiels/WarrantyQuery.api';
import { ChipOfferRisk } from 'components/Chip/ChipOfferRisk';
import { DialogBeneficiaries } from 'components/Dialog/DialogBeneficiaries';
import { ButtonBeneficiaries } from 'components/Button/ButtonBeneficiaries';
import { FormikObserver } from 'utils/formik/FormikObserver';
import { projectToFormulasSelectionMap } from 'utils/project/project';

const CURRENT_STEP = ProjectStep.PROPOSAL;

const ProjectProposalPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const theme = useTheme();
  const screenSizeUpLG = useMediaQuery(theme.breakpoints.up('lg'));

  if (!id) return <></>;

  const { addSnackbar } = useAppContext();
  const { selectedFormulas: allSelectedFormulas, setSelectedFormulas } =
    useProjectContext();
  const refStickyAnchor = useRef<HTMLDivElement>(null);
  const isStickyAnchorVisible = useOnScreen(refStickyAnchor, { threshold: 1 });
  const [showWarranties, setShowWarranties] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const projectQuery = useQuery(projectQueries.getById(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));

  const accessToken = useAccessToken();
  const mutation = useMutation({
    mutationFn: (payload: ProjectStepProposal) =>
      updateProjectStepProposal(accessToken, id, payload),
    onSuccess: (project: Project) => {
      queryClient.setQueryData(['projects', project.id], () => project);

      const nextStep = getNextStep(CURRENT_STEP);
      if (nextStep)
        navigate(`/projets/${id}/${projectStepsData[nextStep]!.path}`);
    },
    onError: () => {
      addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  });

  const contextStatus = useMemo<'loading' | 'error' | 'done'>(() => {
    if (projectQuery.isError || offersMapQuery.isError) return 'error';
    if (
      projectQuery.isLoading ||
      offersMapQuery.isLoading ||
      !projectQuery.data ||
      !offersMapQuery.data
    )
      return 'loading';
    return 'done';
  }, [projectQuery, offersMapQuery]);

  const getSubscriptionFromOfferCode = (code: string) => {
    const subscriptions = projectQuery.data?.subscriptions;
    if (!subscriptions) return;

    const sub = subscriptions.find(_ => _.offerCode === code);
    if (sub) return sub;
    else if (subscriptions.length > 0) return subscriptions[0];
  };

  const [subscriptionCurrent, setSubscriptionCurrent] = useState<
    Subscription | undefined
  >(getSubscriptionFromOfferCode(searchParams.get('offre') as string));

  useEffect(() => {
    setSubscriptionCurrent(
      getSubscriptionFromOfferCode(searchParams.get('offre') as string),
    );
  }, [projectQuery?.data]);

  useEffect(() => {
    if (!subscriptionCurrent?.offerCode) return;
    setSearchParams(prev => ({
      ...prev,
      offre: subscriptionCurrent.offerCode,
    }));
  }, [subscriptionCurrent?.offerCode]);

  const selectedFormulas = useMemo(() => {
    if (subscriptionCurrent?.id) {
      return allSelectedFormulas[subscriptionCurrent.id] ?? [];
    }
    return [];
  }, [subscriptionCurrent, allSelectedFormulas]);

  const onValidate = (values: FormulasSelectionMap) => {
    mutation.mutate({
      subscriptions: Object.entries(values).map(([id, formulaCodes]) => ({
        id,
        shoppingCartItems: formulaCodes.map(formulaCode => ({ formulaCode })),
      })),
    });
  };

  const tarificationsQuery = useQuery({
    ...subscriptionQueries.getByIdTarification(
      id,
      subscriptionCurrent?.id || '',
    ),
    enabled: !!subscriptionCurrent?.id,
  });

  const offerWarrantiesQuery = useQuery({
    ...offerQueries.getByCodeWarranties(subscriptionCurrent?.offerCode || ''),
    enabled: !!subscriptionCurrent?.offerCode,
  });

  const acronymsMapQuery = useQuery(warrantyQueries.getAcronymMap());

  const offer = useMemo(() => {
    if (subscriptionCurrent && offersMapQuery.data) {
      return offersMapQuery.data[subscriptionCurrent.offerCode];
    }
  }, [subscriptionCurrent, offersMapQuery.data]);

  // Form
  const formikRef = useRef<FormikProps<any>>(null);
  const validationSchema = Yup.object().shape({
    dateStart: getYupFieldDateStart().required(messageFieldRequired),
    beneficiariesId: Yup.array().of(Yup.string().required()),
  });

  // Formula form
  const formikFormulaRef = useRef<FormikProps<FormulasSelectionMap>>(null);
  const initialFormulasValues = useMemo<FormulasSelectionMap>(
    () => projectToFormulasSelectionMap(projectQuery.data),
    [projectQuery.data],
  );

  const formulasValidationSchema = useMemo(() => {
    return Yup.object().shape(
      Object.keys(initialFormulasValues).reduce<Record<string, Yup.Schema>>(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue]: Yup.array().min(
            1,
            'Veuillez sélectionner une formule.',
          ),
        }),
        {},
      ),
    );
  }, [initialFormulasValues]);

  const [dialogBeneficiariesOpened, setDialogBeneficiariesOpened] =
    useState<boolean>(false);
  const [dialogBeneficiariesId, setDialogBeneficiariesId] = useState<string[]>(
    [],
  );

  const getInitialValues = () => {
    return {
      dateStart: subscriptionCurrent?.dateStart,
      beneficiariesId: subscriptionCurrent?.beneficiaries?.map(_ => _.id),
    };
  };

  const mutationSubscription = useMutation({
    mutationFn: (payload: SubscriptionRequest) => {
      return updateSubscription(
        accessToken,
        id,
        subscriptionCurrent!.id,
        payload,
      );
    },
    onSuccess: (response: Subscription) => {
      setSubscriptionCurrent(response);
      queryClient.setQueryData(['projects', id], () => {
        const sub = [...(projectQuery.data?.subscriptions || [])];
        return {
          ...projectQuery.data,
          subscriptions: sub?.map(_ =>
            _.id === response.id ? { ..._, ...response } : _,
          ),
        };
      });
      queryClient.invalidateQueries({
        queryKey: ['projects', id, 'subscription', response.id],
      });
      formikRef.current?.resetForm();
    },
    onError: (error: Error) => {
      if (error instanceof RestError && error.status === 400) {
        const e = (error.response as ApiResponse<SubscriptionRequestErrors>)
          .details;
        if (e) onBadRequest(e);
      } else addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  });

  const onBadRequest = (errors: SubscriptionRequestErrors) => {
    formikRef.current?.setFieldError('dateStart', errors.dateStart);

    const beneficiariesError = formikRef.current?.values.beneficiariesId?.map(
      (_: string, idx: number) => errors[`beneficiariesId[${idx}]`],
    );
    formikRef.current?.setFieldError(
      'beneficiariesId',
      beneficiariesError as any,
    );
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
          gap={1}
        >
          <Typography variant="h3" flexGrow={1}>
            Proposition
          </Typography>
          {contextStatus === 'done' &&
            offersMapQuery.data &&
            projectQuery.data &&
            projectQuery.data.subscriptions?.length &&
            projectQuery.data.subscriptions?.length > 1 && (
              <ToggleButtonGroup
                exclusive
                color="primary"
                size="small"
                value={subscriptionCurrent?.offerCode}
                onChange={(e, v) => {
                  setSubscriptionCurrent(getSubscriptionFromOfferCode(v));
                }}
              >
                {projectQuery.data.subscriptions.map(_ => (
                  <ToggleButton value={_.offerCode} key={_.offerCode}>
                    {offersMapQuery.data[_.offerCode].label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
        </Stack>
      </Grid>

      {contextStatus === 'done' &&
      subscriptionCurrent &&
      projectQuery.data &&
      offersMapQuery.data ? (
        <>
          <Grid item xs={12}>
            <Card
              sx={{
                p: 2,
                border: 2,
                borderColor: theme.palette.divider,
              }}
            >
              <Formik
                innerRef={formikRef}
                enableReinitialize
                initialValues={getInitialValues()}
                validationSchema={validationSchema}
                onSubmit={values => {
                  mutationSubscription.mutate({
                    offerCode: subscriptionCurrent.offerCode,
                    formulaCode: subscriptionCurrent.formulaCode,
                    dateStart: values.dateStart,
                    beneficiariesId: values.beneficiariesId,
                  });
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  setFieldTouched,
                  setFieldValue,
                  handleSubmit,
                }) => {
                  const [debouncedDateStart] = useDebounce(
                    values.dateStart,
                    300,
                  );

                  useEffect(() => {
                    setDialogBeneficiariesId(values.beneficiariesId || []);
                  }, [dialogBeneficiariesOpened, values.beneficiariesId]);

                  useEffect(() => {
                    if (
                      Object.entries(touched).length > 0 &&
                      Object.values(touched).every(_ => _)
                    ) {
                      handleSubmit();
                    }
                  }, [values.beneficiariesId, debouncedDateStart]);

                  return (
                    <Form>
                      <Grid container gap={2}>
                        <Grid item lg xs={12}>
                          <Stack
                            gap={1}
                            alignItems="space-between"
                            sx={{
                              height: '100%',
                            }}
                          >
                            <Stack gap={1} flexGrow={1}>
                              <Typography variant="body1">
                                <Stack
                                  direction="row"
                                  gap={1}
                                  flexWrap="wrap-reverse"
                                  useFlexGap
                                >
                                  <b>{offer?.label}</b>

                                  {offer?.risks?.map(_ => (
                                    <ChipOfferRisk key={_.id} risk={_} />
                                  ))}
                                </Stack>
                              </Typography>
                              {offer?.description && (
                                <Typography variant="body2">
                                  {offer.description}
                                </Typography>
                              )}
                            </Stack>
                            {tarificationsQuery.data?.disclaimers
                              ?.tarificationValidity && (
                              <Typography
                                variant="caption"
                                component="div"
                                color="text.primary"
                              >
                                📍{' '}
                                {
                                  tarificationsQuery.data?.disclaimers
                                    ?.tarificationValidity
                                }
                              </Typography>
                            )}
                          </Stack>
                        </Grid>
                        {!screenSizeUpLG && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                        <Grid item lg="auto" xs={12}>
                          <Stack
                            alignItems="stretch"
                            gap={1}
                            flexWrap="wrap"
                            useFlexGap
                            sx={{
                              flexDirection: {
                                lg: 'column',
                                xs: 'row',
                              },
                            }}
                          >
                            <DatePicker
                              label="Début du contrat"
                              format="DD/MM/YYYY"
                              value={subscriptionCurrent?.dateStart}
                              minDate={dayjs()
                                .startOf('day')
                                .add(dateStartDayMin, 'day')}
                              maxDate={dayjs()
                                .startOf('day')
                                .add(dateStartDayMax, 'day')}
                              onChange={v => setFieldValue('dateStart', v)}
                              slotProps={{
                                textField: {
                                  sx: {
                                    width: {
                                      xs: '100%',
                                      sm: 'auto',
                                      lg: '100%',
                                    },
                                  },
                                  required: true,
                                  onBlur: () => setFieldTouched('dateStart'),
                                  error:
                                    touched.dateStart &&
                                    Boolean(errors.dateStart),
                                  helperText: touched.dateStart &&
                                    Boolean(errors.dateStart) && (
                                      <>{errors.dateStart}</>
                                    ),
                                },
                              }}
                            />

                            <ButtonBeneficiaries
                              project={projectQuery.data}
                              beneficiariesLength={dialogBeneficiariesId.length}
                              sx={{
                                width: {
                                  xs: '100%',
                                  sm: 'auto',
                                  lg: '100%',
                                },
                              }}
                              onClick={() => setDialogBeneficiariesOpened(true)}
                            />
                          </Stack>
                        </Grid>
                      </Grid>

                      <DialogBeneficiaries
                        project={projectQuery.data}
                        beneficiaryIds={dialogBeneficiariesId}
                        onCancel={() => setDialogBeneficiariesOpened(false)}
                        onValidate={() => {
                          setFieldValue(
                            'beneficiariesId',
                            dialogBeneficiariesId,
                          );
                          setFieldTouched('beneficiariesId');
                          setDialogBeneficiariesOpened(false);
                        }}
                        onClick={id => {
                          const benef = [...dialogBeneficiariesId];
                          if (benef.includes(id)) {
                            const index = benef.indexOf(id);
                            if (index > -1) benef.splice(index, 1);
                          } else {
                            benef.push(id);
                          }

                          setDialogBeneficiariesId(benef);
                        }}
                        open={dialogBeneficiariesOpened}
                        offer={
                          offersMapQuery.data[subscriptionCurrent.offerCode]
                        }
                      />
                    </Form>
                  );
                }}
              </Formik>
            </Card>
          </Grid>

          <Box sx={{ height: 0 }} ref={refStickyAnchor}></Box>

          <Grid
            item
            xs={12}
            sx={{
              zIndex: 1000,
              position: 'sticky',
              top: 0,
              mt: 1,
            }}
          >
            <Formik
              innerRef={formikFormulaRef}
              initialValues={initialFormulasValues}
              validationSchema={formulasValidationSchema}
              onSubmit={onValidate}
            >
              {({ values, setFieldValue, errors, touched }) => {
                const selectedFormulas = values[subscriptionCurrent.id];
                return (
                  <Form>
                    <FormikObserver onChange={setSelectedFormulas} />
                    <Box sx={{ height: 8 }}></Box>
                    {subscriptionCurrent && (
                      <Grid container columnSpacing={1} rowSpacing={2}>
                        {offersMapQuery.data[
                          subscriptionCurrent.offerCode
                        ].formulas.map(_ => {
                          const pricing = tarificationsQuery.data
                            ? tarificationsQuery.data.formulas?.find(
                                f => f.code === _.code,
                              )?.tarification?.pricing
                            : undefined;

                          const selected = selectedFormulas.includes(_.code);
                          const disabled =
                            selectedFormulas.length > 2 && !selected;

                          return (
                            <Grid
                              item
                              key={_.code}
                              lg={
                                12 /
                                offersMapQuery.data[
                                  subscriptionCurrent.offerCode
                                ].formulas.length
                              }
                              xs={12}
                            >
                              <CardFormula
                                formula={_}
                                pricing={pricing}
                                recommended={
                                  _.code ===
                                  subscriptionCurrent.recommendedFormulaCode
                                }
                                selected={selected}
                                disabled={disabled}
                                onClick={() => {
                                  const newSelectedFormulas = selected
                                    ? selectedFormulas.filter(f => f !== _.code)
                                    : [...selectedFormulas, _.code];
                                  setFieldValue(
                                    subscriptionCurrent.id,
                                    newSelectedFormulas,
                                  );
                                }}
                              />
                            </Grid>
                          );
                        })}

                        {touched[subscriptionCurrent.id] &&
                          errors[subscriptionCurrent.id] && (
                            <Grid item xs={12}>
                              <FormHelperText error>
                                {errors[subscriptionCurrent.id]}
                              </FormHelperText>
                            </Grid>
                          )}
                      </Grid>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: {
                lg: 'block',
                xs: 'none',
              },
              pt: !showWarranties ? '0px !important' : 2,
              pb: isStickyAnchorVisible ? 0 : 2,
            }}
          >
            <Collapse in={showWarranties}>
              <Suspense>
                <Grid container spacing={2}>
                  {offerWarrantiesQuery.data?.map((section, idx) => {
                    const formulas =
                      offersMapQuery.data[subscriptionCurrent.offerCode]
                        .formulas;

                    return (
                      <Grid item xs={12} key={`warranties-section-${idx}`}>
                        <WarrantySectionAccordion
                          section={section}
                          formulas={formulas}
                          formulasSelected={selectedFormulas}
                          acronymsMap={acronymsMapQuery.data}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Suspense>
            </Collapse>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              position: 'sticky',
              bottom: 16,
              pt: !isStickyAnchorVisible ? '0px !important' : 2,
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                sm="auto"
                xs={12}
                order={{
                  sm: 0,
                  xs: 1,
                }}
              >
                <Button
                  fullWidth
                  color="default"
                  disabled={mutation.isPending}
                  onClick={() => {
                    const prevStep = getPreviousStep(CURRENT_STEP);
                    if (prevStep)
                      navigate(
                        `/projets/${id}/${projectStepsData[prevStep]!.path}`,
                      );
                  }}
                  sx={{ px: 4 }}
                >
                  Retour
                </Button>
              </Grid>
              <Grid
                item
                sm="auto"
                xs={12}
                order={{
                  sm: 0,
                  xs: 1,
                }}
              >
                <Button
                  fullWidth
                  disabled={mutation.isPending}
                  onClick={formikFormulaRef.current?.submitForm}
                  sx={{ px: 4 }}
                >
                  {mutation.isPending ? (
                    <CircularProgress color="inherit" size={28} />
                  ) : (
                    'Valider'
                  )}
                </Button>
              </Grid>

              <Grid
                item
                sx={{
                  display: {
                    lg: 'block',
                    xs: 'none',
                  },
                  height: '32px',
                }}
              >
                <Divider orientation="vertical" />
              </Grid>

              {!showWarranties ? (
                <>
                  <Grid item xs="auto">
                    <Tooltip title="Afficher les garanties" placement="top">
                      <Button
                        color="default"
                        onClick={() => setShowWarranties(true)}
                        sx={{
                          minWidth: 0,
                          p: 1,
                        }}
                      >
                        <VisibilityIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs="auto">
                    <Tooltip title="Masquer les garanties" placement="top">
                      <Button
                        color="default"
                        onClick={() => setShowWarranties(false)}
                        sx={{
                          minWidth: 0,
                          p: 1,
                        }}
                      >
                        <VisibilityOffIcon />
                      </Button>
                    </Tooltip>
                  </Grid>

                  {!isStickyAnchorVisible && (
                    <Grid item xs="auto">
                      <Tooltip title="Remonter" placement="top">
                        <Button
                          color="default"
                          onClick={() =>
                            refStickyAnchor.current?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'nearest',
                            })
                          }
                          sx={{
                            minWidth: 0,
                            p: 1,
                          }}
                        >
                          <ChevronBottomIcon
                            size="medium"
                            style={{ transform: 'rotate(180deg)' }}
                          />
                        </Button>
                      </Tooltip>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </>
      ) : contextStatus === 'error' ? (
        <Grid
          item
          xs={12}
          sx={{
            height: 200,
          }}
        >
          <CardError
            status={
              projectQuery.error instanceof RestError
                ? projectQuery.error.status
                : undefined
            }
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" animation="wave" height="200px" />
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectProposalPage;
