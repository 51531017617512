import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Skeleton from '@mui/material/Skeleton';
import { appName } from 'App';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import {
  navigateToNextStep,
  navigateToPreviousStep,
} from 'utils/project/project';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import CardError from 'components/Card/CardError';
import { RestError } from 'errors/RestError';
import { useProjectStepFormulaChoice } from 'api/oav/ProjectStep.api';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { useAppContext } from 'components/Context/AppContext';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { supportingDocumentIcons } from 'models/referentiels/Document.models';
import { IconProps } from 'components/Icon/settings';
import Tooltip from '@mui/material/Tooltip';
import { FormControlLabel } from '@mui/material';
import { InfoIcon } from 'components/Icon/InfoIcon';
import Box from '@mui/material/Box';
import TextField from 'components/TextField/TextField';
import Checkbox from '@mui/material/Checkbox';
import { messageFieldRequired } from 'utils/validation/message';
import { Form, Formik } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import { supportingDocumentsQueries } from 'api/oav/SupportingDocumentQuery.api';

const CURRENT_STEP = ProjectStep.CONTRACT;

interface FormikValues {
  email: string;
  confirm: boolean;
  // TODO: change type when upload document will be developed
  supportingDocuments: string;
}

const validationSchema = Yup.object().shape({
  confirm: Yup.boolean().isTrue('Confirmation obligatoire'),
  email: Yup.string().required(messageFieldRequired).email('Email non valide'),
  // TODO: change validation when upload document will be developed
  supportingDocuments: Yup.string().required(
    'Veuillez compléter toutes les pièces justificatives',
  ),
});

const ProjectContractPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) return <></>;

  const theme = useTheme();

  const projectQuery = useQuery(projectQueries.getById(id));
  const supportingDocumentsQuery = useQuery(
    supportingDocumentsQueries.getSupportingDocumentsOptions(id),
  );

  const { addSnackbar } = useAppContext();

  const { mutate, isPending } = useProjectStepFormulaChoice(id, {
    onSuccess: () => {
      navigateToNextStep(CURRENT_STEP, id, navigate);
    },
    onError: () => {
      addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  });

  const initialValues = useMemo<FormikValues>(
    () => ({
      email: projectQuery.data?.subscriber?.person?.email || '',
      confirm: false,
      supportingDocuments: '',
    }),
    [projectQuery.data],
  );

  if (projectQuery.error || supportingDocumentsQuery.error) {
    const error = projectQuery.error || supportingDocumentsQuery.error;
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: 200,
        }}
      >
        <CardError
          status={error instanceof RestError ? error.status : undefined}
        />
      </Grid>
    );
  }

  if (!projectQuery.data || !supportingDocumentsQuery.data) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  const supportingDocumentTypes = supportingDocumentsQuery.data;

  const handleSubmit = (values: FormikValues) => {
    // TODO: handle submit when upload document will be developed
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <Form>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h3">Contractualisation</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginY: 2 }}>
                  <Typography variant="h3">
                    Transmission des pièces et signature :
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Card
                    sx={{
                      p: 3,
                      borderColor: theme.palette.divider,
                    }}
                  >
                    <Typography variant="body1">
                      Les pièces justificatives de votre client sont nécessaires
                      à la validation de sa souscription.
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      p: 3,
                      borderColor: theme.palette.divider,
                    }}
                  >
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      Transmettez les pièces justificatives et complétez les
                      informations :
                    </Typography>
                    <Stack direction="column" spacing={2} alignItems="center">
                      {Object.entries(supportingDocumentTypes).map(([, _]) => (
                        <Card
                          key={_.type}
                          sx={{
                            width: '100%',
                            paddingRight: 2,
                            paddingLeft: 4,
                            paddingY: 0,
                            backgroundColor: theme.palette.background.default,
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {React.createElement<IconProps>(
                              supportingDocumentIcons[_.type].icon,
                              {
                                color: theme.palette.text.disabled,
                                width: '70',
                                height: '70',
                              },
                            )}
                            <Typography sx={{ marginLeft: 3 }} variant="body1">
                              {_.label}
                            </Typography>
                            <Tooltip
                              title={_.description}
                              placement="top"
                              arrow
                            >
                              <Box
                                sx={{
                                  marginY: 'auto',
                                  marginLeft: 2,
                                  width: '20px',
                                  height: '20px',
                                }}
                              >
                                <InfoIcon
                                  color={theme.palette.text.disabled}
                                  size="small"
                                />
                              </Box>
                            </Tooltip>
                            <Button
                              variant="gradient"
                              sx={{
                                color: theme.palette.background.default,
                                background:
                                  'linear-gradient(270deg, #52E09C 0%, #59CCD3 100%)',
                                paddingX: 4,
                                marginLeft: 'auto',
                              }}
                            >
                              Compléter
                            </Button>
                          </Stack>
                        </Card>
                      ))}
                      {touched.supportingDocuments &&
                        !!errors.supportingDocuments && (
                          <FormHelperText
                            error
                            sx={{ alignSelf: 'flex-start' }}
                          >
                            {errors.supportingDocuments}
                          </FormHelperText>
                        )}
                      {}
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      p: 3,
                      borderColor: theme.palette.divider,
                    }}
                  >
                    <Stack direction="column" spacing={2}>
                      <Typography variant="body1">Informations</Typography>
                      <TextField
                        variant="filled"
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                        id="email"
                        name="email"
                        label="Email de votre client*"
                        value={values.email}
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ minLength: 1, maxLength: 100 }}
                      />
                      <span>
                        <FormControlLabel
                          control={<Checkbox />}
                          checked={values.confirm}
                          name="confirm"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{
                            color:
                              touched.confirm && !!errors.confirm
                                ? theme.palette.error.main
                                : undefined,
                          }}
                          label="Je confirme que l’ensemble des pièces justificatives demandées a été vérifié avant souscription.*"
                        />
                        {touched.confirm && !!errors.confirm && (
                          <FormHelperText error>
                            {errors.confirm}
                          </FormHelperText>
                        )}
                      </span>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm="auto" xs={12}>
              <Button
                fullWidth
                color="default"
                disabled={isPending}
                onClick={() =>
                  navigateToPreviousStep(CURRENT_STEP, id, navigate)
                }
                sx={{ px: 4 }}
              >
                Retour
              </Button>
            </Grid>

            <Grid item sm="auto" xs={12}>
              <Button
                fullWidth
                type="submit"
                disabled={isPending}
                sx={{ px: 4 }}
              >
                {isPending ? (
                  <CircularProgress color="inherit" size={28} />
                ) : (
                  'Valider'
                )}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectContractPage;
