import { RestError } from 'errors/RestError';
import { useMutation } from '@tanstack/react-query';
import { useAccessToken } from 'utils/api/api';
import {
  CommercialProposition,
  CommercialPropositionRequest,
} from 'models/oav/CommercialProposition.models';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const createCommercialProposition = async (
  accessToken: string,
  projectId: string,
  payload: CommercialPropositionRequest,
): Promise<CommercialProposition> => {
  const url = `${baseUrl}/${projectId}/commercial-proposition`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'POST',
      JSON.stringify(payload),
      await response.json(),
    );

  return await response.json();
};

export const useCreateCommercialPropositionMutation = (
  onSuccess?: (data: CommercialProposition) => void,
  onError?: (error: Error) => void,
) => {
  const accessToken = useAccessToken();
  return useMutation({
    mutationFn: (params: {
      projectId: string;
      payload: CommercialPropositionRequest;
    }) =>
      createCommercialProposition(
        accessToken,
        params.projectId,
        params.payload,
      ),
    onSuccess: (commercialProposition: CommercialProposition) => {
      onSuccess?.(commercialProposition);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};
