import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link as LinkMUI } from '@mui/material';

import AppBar from 'components/AppBar';
import { appName } from 'App';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';
import { PersonView } from './PersonView';
import { PersonUpdate } from './PersonUpdate';
import { personGetByIdOptions } from 'api/oav/PersonQuery.api';
import { RestError } from 'errors/RestError';
import CardError from 'components/Card/CardError';
import { ContractTab, ContractTable } from '../../wallet/ContractTable';

const PersonPage: React.FC = () => {
  document.title = `Personnes - ${appName}`;

  const navigate = useNavigate();
  const { uuid } = useParams();
  const [mode, setMode] = useState<'view' | 'update'>('view');

  if (!uuid) {
    navigate('/personnes');
    return null;
  }

  const { data: person, error } = useQuery(personGetByIdOptions(uuid));

  const getSubscriberCode = () => {
    return person
      ? person.delegatePersonCode
        ? [person.delegatePersonCode]
        : []
      : undefined;
  };

  const contractTabs: ContractTab[] = [
    { key: 'effectiveDate' },
    { key: 'riskLabel' },
    { key: 'segmentLabel' },
    { key: 'offerLabel' },
    { key: 'code' },
    { key: 'status' },
    { key: 'cancelDate' },
  ];

  return (
    <>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
          sx={{
            width: '100%',
          }}
        >
          <Breadcrumbs separator={<ChevronRightIcon size="small" />}>
            <Link to="/personnes" style={{ color: 'inherit' }}>
              <LinkMUI underline="hover" variant="body2" color="inherit">
                Personnes
              </LinkMUI>
            </Link>
            {person && (
              <Typography color="text.primary">
                {person.firstname} {person.lastname}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>
      </AppBar>

      {error ? (
        <Box
          display="flex"
          justifyContent="center"
          sx={{ pt: 4, width: '100%', height: 200, marginX: 'auto' }}
        >
          <Grid container>
            <Grid item xs={12}>
              <CardError status={(error as RestError).status} />
            </Grid>
          </Grid>
        </Box>
      ) : !person ? (
        <Box
          display="flex"
          justifyContent="center"
          sx={{ pt: 4, width: '100%', height: 200, marginX: 'auto' }}
        >
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" animation="wave" height="300px" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" animation="wave" height="300px" />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          useFlexGap
          spacing={1}
        >
          {mode === 'view' && (
            <PersonView person={person} onUpdate={() => setMode('update')} />
          )}

          {mode === 'update' && (
            <PersonUpdate
              person={person}
              onBackToViewMode={() => setMode('view')}
            />
          )}

          {person && person.delegatePersonCode && (
            <ContractTable
              searchRequest={{ subscriberCodes: getSubscriberCode() }}
              tabs={contractTabs}
              onContractFetched={() => {}}
              pageSize={10}
              emptyMessage={
                "Vous n'avez pas de contrat en portefeuille associé à ce client."
              }
            />
          )}
        </Stack>
      )}
    </>
  );
};

export default PersonPage;
