import { ReactComponentLike } from 'prop-types';
import { DocumentIDIcon } from 'components/Icon/DocumentIDIcon';
import { DocumentRIBIcon } from 'components/Icon/DocumentRIBIcon';
import { DocumentMajorityIcon } from 'components/Icon/DocumentMajorityIcon';
import { DocumentIcon } from 'components/Icon/DocumentIcon';

export interface StaticDocument {
  id: string;
  mimeType: string;
  filename: string;
  type: {
    label: string;
    code: string;
  };
  downloadUrl: string;
}

export interface DocumentDownload {
  url: string;
}

export interface SupportingDocumentType {
  code: SupportingDocumentTypeCode;
  label: string;
  description: string;
}

export enum SupportingDocumentTypeCode {
  ID = 'ID',
  CPAM = 'CPAM',
  BANK_DETAILS = 'BANK_DETAILS',
  CHILD_MAJORITY = 'CHILD_MAJORITY',
  KBIS = 'KBIS',
}

export const supportingDocumentIcons: {
  [key in SupportingDocumentTypeCode]: { icon: ReactComponentLike };
} = {
  [SupportingDocumentTypeCode.ID]: { icon: DocumentIDIcon },
  [SupportingDocumentTypeCode.CPAM]: { icon: DocumentIcon },
  [SupportingDocumentTypeCode.BANK_DETAILS]: { icon: DocumentRIBIcon },
  [SupportingDocumentTypeCode.CHILD_MAJORITY]: { icon: DocumentMajorityIcon },
  [SupportingDocumentTypeCode.KBIS]: { icon: DocumentIcon },
};
