import { PageResponse } from 'models/api.model';
import { StaticDocument } from 'models/referentiels/Document.models';
import { Pagination } from 'models/Page.models';
import { Offer, OfferEligibility } from 'models/referentiels/Offer.model';

import { buildParams } from 'utils/api/params';

import { RestError } from 'errors/RestError';
import { WarrantySection } from 'models/referentiels/Warranty.model';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/referentiels/offer`;

export interface OfferFilters {
  inRisks?: string[];
  inSegments?: string[];
  search?: string;
}

export const fetchOffers = async (
  accessToken: string,
  pagination?: Pagination,
  filters?: OfferFilters,
  sort?: string[],
): Promise<PageResponse<Offer>> => {
  const params = new URLSearchParams();
  if (pagination)
    buildParams(pagination, 'pagination')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (filters)
    buildParams(filters, 'filters')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (sort) params.append('sort', sort.join(','));

  const url = params.size > 0 ? `${baseUrl}?${params}` : baseUrl;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return response.json();
};

export const fetchOffersEligibility = async (
  accessToken: string,
  context: OfferEligibility,
  pagination?: Pagination,
  filters?: OfferFilters,
  sort?: string[],
): Promise<PageResponse<Offer>> => {
  const params = new URLSearchParams();
  if (pagination)
    buildParams(pagination, 'pagination')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (filters)
    buildParams(filters, 'filters')?.forEach(([key, value]) =>
      params.append(key, value),
    );
  if (sort) params.append('sort', sort.join(','));

  const url: string =
    params.size > 0 ? `${baseUrl}/eligibility?${params}` : baseUrl;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(context),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'POST',
      context,
      await response.json(),
    );

  return await response.json();
};

export const fetchOfferByCode = async (
  accessToken: string,
  code: string,
): Promise<Offer> => {
  const url = `${baseUrl}/${code}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};

export const fetchOfferByCodeEligibility = async (
  accessToken: string,
  code: string,
  context: OfferEligibility,
): Promise<Offer> => {
  const url = `${baseUrl}/${code}/eligibility`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(context),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'POST',
      context,
      await response.json(),
    );

  return await response.json();
};

export const fetchOfferDocuments = async (
  accessToken: string,
  code: string,
): Promise<StaticDocument[]> => {
  const url = `${baseUrl}/${code}/documents`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};

export const fetchOfferWarranties = async (
  accessToken: string,
  code: string,
): Promise<WarrantySection[]> => {
  const url = `${baseUrl}/${code}/warranties`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'GET',
      undefined,
      await response.json(),
    );

  return await response.json();
};
