import { ContractSearchRequest } from 'models/oav/Contract.model';
import { Pagination } from 'models/Page.models';
import { queryOptions } from '@tanstack/react-query';
import { useAccessToken } from 'utils/api/api';
import { fetchContractByCode, fetchContractList } from './Contract.api';

export const contractGetListOptions = (
  pagination: Pagination,
  criteria: ContractSearchRequest,
  sort: string,
) => {
  const accessToken = useAccessToken();

  return queryOptions({
    queryKey: ['contracts', 'list', criteria, pagination.size, pagination.page, sort],
    queryFn: () => fetchContractList(pagination, criteria, sort, accessToken),
    gcTime: 300000, // 5min
    staleTime: 300000, // 5min
  });
};

export const contractGetByCodeOptions = (code: string) => {
  const accessToken = useAccessToken();

  return queryOptions({
    queryKey: ['contract', code],
    queryFn: () => fetchContractByCode(code, accessToken),
    gcTime: 300000, // 5min
    staleTime: 300000, // 5min
  });
};
